import * as THREE from 'three';

//const capitalize = (string) => {
  //return string.charAt(0).toUpperCase() + string.slice(1);
//};

export default class Light {
  constructor(scene, type) {
    this.type = type;

    switch (this.type) {
      case 'point':
        this.light = new THREE.PointLight(0xffffff, 10, 5);
        this.light.position.set(0, 5, 2);
        break;

      case 'directional':
        this.light = new THREE.DirectionalLight(0xffffff, 1, 10);
        this.light.castShadow = true;
        this.light.shadow.bias = 0;
        this.light.position.set(0, 5, 2);
        break;

      case 'hemisphere':
        this.light = new THREE.HemisphereLight(0x000000, 0xffffff, 1.0);
        this.light.position.set(0, 0, 0);
        break;

      case 'ambient':
        this.light = new THREE.AmbientLight(0xffffff);
        this.light.position.set(0, 15, 0);
        break;

      default:
        return null;
    }

    scene.add( this.light );
  }

  update(time) {
    if (this.type === 'point') {
      //this.light.intensity = (Math.cos(time * 3.375) * 0.7) + 1;
      //this.light.color.setHSL( 0.5, 0, Math.sin(time * 3.375) + 1 );
    }
  }
}
