class Node {
  constructor(cube, next) {
    this.cube = cube;
    this.next = next;
  }
}

export default class LinkedList {
  constructor() {
    this.head = null;
  }

  addToHead( cube ) {
    const newNode = new Node( cube, this.head );
    if (this.head) {
      this.head.prev = newNode;
    }
    else {
      //this.tail = newNode;
    }
    this.head = newNode;
  }
}

