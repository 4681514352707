import App from './App';

const app = new App();

const resizeCanvas = () => {
  app.onWindowResize();
};

const mouseMove = (e) => {
  app.onMouseMove({
    x: e.touches ? e.touches[0].clientX : e.clientX,
    y: e.touches ? e.touches[0].clientY : e.clientY
  });
};

const bindEventListeners = () => {
  window.onresize = resizeCanvas;
  window.onmousemove = mouseMove;
  resizeCanvas();
};

const render = () => {
  requestAnimationFrame( render );

  app.update();
};

bindEventListeners();
render();
