import Cube from './Cube';
import LinkedList from './LinkedList';

import gsap, { Power1 } from 'gsap';
import { toBinaryString, getRandomInRange } from '../utils';

const MAX_CUBES = 13;
const MIN_CUBES = 5;
const SPEED = 1; //seconds

const positions = [
  [0, 0,   0],
  [0, 1.2, 0],
  [0, 2.4, 0],
  [0, 3.6, 0],
];

export default class Digit {
  constructor(scene) {
    this.scene = scene;
    this.needsUpdate = false; // TODO: update visually when `time` has changed
    this.cubeList = new LinkedList();

    this.setup();
  }

  setup() {
    var length = ~~(Math.random() * MAX_CUBES - 1) + MIN_CUBES;

    for (let i = 0; i < length; i++) {
      this.cubeList.addToHead(
        new Cube(this.scene, {
          pos: {
            x: getRandomInRange(-4, 4),
            y: getRandomInRange(-4, 4),
            z: getRandomInRange(-4, 4)
          },
          delta: {
            x: getRandomInRange(-0.01, 0.01),
            y: getRandomInRange(-0.01, 0.01),
            z: getRandomInRange(-0.01, 0.01),
          }
        })
      );
    }

    var cubeNow = this.cubeList.head;

    while (cubeNow) {
      let config = {
        ease: Power1.easeInOut,
        x: getRandomInRange(-4, 4),
        y: getRandomInRange(-4, 4),
        z: getRandomInRange(-4, 4),
      };

      gsap.to(
        cubeNow.cube.position,
        SPEED,
        config
      );

      cubeNow = cubeNow.next;
    }
  }

  update(time) {
    //
  }

  updatePositions() {
    var cubeNow = this.cubeList.head;

    while (cubeNow) {
      let config = {
        ease: Power1.easeInOut,
        x: getRandomInRange(-4, 4),
        y: getRandomInRange(-4, 4),
        z: getRandomInRange(-4, 4),
        scale: getRandomInRange(1, 2),
      };

      gsap.to(
        cubeNow.cube.position,
        SPEED,
        config
      );

      cubeNow = cubeNow.next;
    }
  }
}
