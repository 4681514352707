import * as THREE from 'three';

import Light from './components/Light';
import World from './components/World';
import Digit from './components/Digit';

import { getBinary } from './utils.js';

//const bpm = 6.5; // TODO calculate value from real bpm value
const clock = new THREE.Clock();
const renderer = new THREE.WebGLRenderer({
  antialias: true
});
renderer.shadowMap.enabled = true;
//renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap
renderer.shadowMap.type = THREE.PCFShadowMap;

//const uniforms = {
//  u_time: { value: 0 },
//};

export default class App {
  constructor() {
    this.mouse = {x:0, y:0},
    this.oldtime = 0;
    this.clock = this.getClock();
    //this.uniforms = this.getUniforms();
    this.renderer = this.createRenderer();
    this.scene = this.createScene();
    this.camera = this.createCamera();
    this.subjects = this.createSceneSubjects();

    this.digit = new Digit(this.scene);
    //this.world = new World(this.scene, this.uniforms);
    this.world = new World(this);

    this.setup();
  }

  getClock() {
    return clock;
  }

  //getUniforms() {
  //  return uniforms;
  //}

  createRenderer() {
    this.canvas = renderer.domElement;
    return renderer;
  }

  createScene() {
    const scene = new THREE.Scene();
    return scene;
  }

  createCamera() {
    let FOV = 25;
    let near = 0.1;
    let far = 1000;
    const camera = new THREE.PerspectiveCamera(FOV, window.innerWidth/window.innerHeight, near, far);
    return camera;
  }

  createSceneSubjects() {
    const scene = this.scene;

    const subjects = [
      //new Light(scene, 'point'),
      new Light(scene, 'directional'),
      new Light(scene, 'hemisphere'),
      new Light(scene, 'ambient'),
    ];

    return subjects;
  }

  setup() {
    document.body.appendChild( this.canvas );

    this.camera.position.set(0, 0, 20);
    //this.camera.lookAt(0, 0, 0);

    this.renderer.setClearColor(0x333333);
  }

  update() {
    var elapsedTime = this.clock.getElapsedTime();
    var elapsedSeconds = ~~( elapsedTime );

    //this.uniforms.u_time = elapsedTime;

    this.world.update( elapsedTime );

    if ( this.oldtime !== elapsedSeconds ) {
      this.digit.updatePositions();
      this.oldtime = elapsedSeconds;
    }

    this.digit.update( elapsedTime );

    renderer.render(this.scene, this.camera);
  }

  //updateTime(time) {
  //  let number = time % 2;

  //  console.log( number );
  //}

  onMouseMove(mouse) {
    this.mouse.x = mouse.x;
    this.mouse.y = mouse.y;
  }

  onWindowResize() {
    this.canvas.style.width = '100%';
    this.canvas.style.height = '100%';

    this.canvas.width = this.canvas.offsetWidth;
    this.canvas.height = this.canvas.offsetHeight;

    this.camera.aspect = this.canvas.offsetWidth / this.canvas.offsetHeight;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(this.canvas.offsetWidth, this.canvas.offsetHeight);
  }
}
