export function toBinaryString(value) {
  return (typeof value === 'number') ? (value).toString(2) : false;
}

export function getRandom() {
  return Math.random() * 0.01;
}

export function getRandomInRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
