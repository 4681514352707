import * as THREE from 'three';

export default class Cube {
  constructor(scene, config) {
    this.geometry = new THREE.BoxGeometry(1, 1, 1);
    this.material = new THREE.MeshLambertMaterial({ color: 0x555555, transparent: true});
    this.cube = new THREE.Mesh(this.geometry, this.material);
    this.cube.castShadow = true;
    this.cube.receiveShadow = true;

    this.setup(config);

    scene.add( this.cube );
  }

  get opacity() { return this.cube.material.opacity; }

  get position() { return this.cube.position; }
  get posX() { return this.cube.position.x; }
  get posY() { return this.cube.position.y; }
  get posZ() { return this.cube.position.z; }

  get rotX() { return this.cube.rotation.x; }
  get rotY() { return this.cube.rotation.y; }
  get rotZ() { return this.cube.rotation.z; }

  get scale() { return this.cube.scale; }

  set opacity(v) { this.cube.material.opacity = v; }

  set posX(v) { this.cube.position.x = v; }
  set posY(v) { this.cube.position.y = v; }
  set posZ(v) { this.cube.position.z = v; }

  set rotX(v) { this.cube.rotation.x = v; }
  set rotY(v) { this.cube.rotation.y = v; }
  set rotZ(v) { this.cube.rotation.z = v; }

  set scale(v) { this.cube.scale = v; }

  setup(config) {
    const { pos, delta } = config;

    //this.delta = [delta.x, delta.y, delta.z];
    this.cube.position.set(pos.x, pos.y, pos.z);
  }
}
