import * as THREE from 'three';

import vertexShader from '../shaders/World.vert';
import fragmentShader from '../shaders/World.frag';

const uniforms = {
  u_color: { value: new THREE.Color(0x666666) },
  u_mouse: { value: { x: 0.0, y: 0.0 } },
  u_time: { value: 0.0 },
  u_resolution: { value: { x: window.innerWidth, y: window.innerHeight } },
};

export default class World {
  constructor(app) {
    this.clock = app.clock;
    this.mouse = app.mouse;

    this.createWorld(app.scene);
  }

  createWorld(scene) {
    this.geometry = new THREE.PlaneGeometry(1, 1, 1);
    this.material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: vertexShader,
      fragmentShader: fragmentShader
    });
    this.plane = new THREE.Mesh(this.geometry, this.material);
    this.plane.receiveShadow = true;

    this.plane.position.set(0, 0.0, -5);
    this.plane.scale.set(8, 8, 8);

    this.plane.onBeforeRender = (...args) => { this.beforeRender(...args) };

    scene.add( this.plane );
  }

  beforeRender(renderer, scene, camera, geometry, material, group) {
    material.uniforms.u_mouse.value = this.mouse;
    material.uniforms.u_time.value = this.clock.getElapsedTime();
  }

  update(time) {
    //console.log( this.mouse )
  }
}
